import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const PineyeContainer = styled.section(
	() => css`
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		/* padding: 20px 0 16px; */
		user-select: none;
		touch-action: none !important;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		-webkit-transform: translateZ(0);
		-webkit-backface-visibility: hidden;
		-webkit-perspective: 1000;
		z-index: 2;
		.armor {
			width: 98%;
			position: relative;
			z-index: 4;
			top: -10px;
			&.whit_pet {
				inset-inline-start: -12%;
			}
		}

		.pet {
			width: 100%;
			position: absolute;
			z-index: 4;
			top: -20px;
			inset-inline-start: 4%;
			z-index: -1;
		}

		@media (max-width: 375px) {
			/* height: 360px; */
			.armor {
				top: -70px;
			}
			.pet {
				top: -75px;
			}
		}
		@media (max-width: 345px) and (max-height: 900px) {
			.armor {
				top: 5px;
			}
			.pet {
				top: -5px;
			}
		}
		@media (max-width: 320px) {
			/* height: 360px; */
			.armor {
				top: -70px;
				width: 90%;
			}
			.pet {
				top: -85px;
			}
		}
		@media (max-height: 900px) {
			/* height: 360px; */
			.armor {
				/* top: -6vh; */
				/* width: 110%; */
			}
		}
	`,
);
